import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Sidebar from '../Sidebar';
import ChatArea from '../ChatArea';
import '../Chat.css';

const ChatApp = () => {
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);

  // Use useSelector to get user information from Redux state
  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const access_token = userInfo?.access;
  const username = userInfo?.username;  // Extract username from user info
  const profilePic = userInfo?.profilePic;  // Extract profile picture from user info

  useEffect(() => {
    if (!access_token) {
      console.error('Access token not found! Chat features will be disabled.');
      return;
    }

    console.log('Setting up WebSocket connection...');

    // Establish WebSocket connection
    const ws = new WebSocket('wss://api.andrewslearning.com/ws/chatroom/');
    setSocket(ws);

    ws.onopen = () => {
      console.log('WebSocket connection established');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Message from server:', data);

      setMessages((prevMessages) => [...prevMessages, { text: data.message, sent: false, author: data.author }]);
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = (event) => {
      console.log('WebSocket connection closed', event);
    };

    return () => {
      console.log('Cleaning up WebSocket connection...');
      ws.close();
    };
  }, [access_token]);

  const sendMessage = (message) => {
    console.log('Attempting to send message:', message);

    if (socket && socket.readyState === WebSocket.OPEN) {
      console.log('WebSocket is open, sending message:', message);

      socket.send(JSON.stringify({ message, author: username }));

      setMessages((prevMessages) => [...prevMessages, { text: message, sent: true, author: username }]);
    } else {
      console.error('WebSocket is not connected or open. Current readyState:', socket?.readyState);
    }
  };

  if (!access_token) {
    return (
      <div className='chat-container'>
        <Sidebar />
        <div className="no-access">
          <h2>Access Denied</h2>
          <p>You must be logged in to use the chat features. Please log in and try again.</p>
        </div>
      </div>
    );
  }

  return (
    <div className='chat-container'>
      <Sidebar />
      {/* Display user's profile picture if available */}
      {profilePic && <img src={profilePic} alt="Profile" className="profile-pic" />}
      <ChatArea sendMessage={sendMessage} messages={messages} />
    </div>
  );
};

export default ChatApp;
