import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'; // Import useSelector to get access_token and user information
import Sidebar from '../Sidebar';
import ChatArea from '../ChatArea';
import '../Chat.css';

const Chatapplocal = () => {
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);

  // Use useSelector to get access_token and user information from Redux state
  const access_token = useSelector(state => state.userLogin.userInfo?.access);
  const userInfo = useSelector(state => state.userLogin.userInfo);

  // Extract username from user information
  const username = userInfo ? userInfo.username : 'Anonymous';

  useEffect(() => {
    if (!access_token) {
      console.error('Access token not found! Chat features will be disabled.');
      return; // Exit early if no access token
    }

    console.log('Setting up WebSocket connection...');

    // Set up WebSocket connection without using the token in the URL
    const ws = new WebSocket('wss://api.andrewslearning.com/ws/gptchatroom/');
    setSocket(ws); // Save WebSocket instance to state

    // Handle WebSocket connection open event
    ws.onopen = () => {
      console.log('WebSocket connection established');
      ws.send(JSON.stringify({ message: 'Hello from client!', author: username }));
    };

    // Handle messages received from the server
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Message from server:', data);

      // Add received message to the messages state with the author information
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: data.message, sent: false, author: data.author || 'Server' }
      ]);
    };

    // Handle WebSocket error
    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    // Handle WebSocket connection close
    ws.onclose = (event) => {
      console.log('WebSocket connection closed', event);
    };

    // Cleanup WebSocket connection when component unmounts
    return () => {
      console.log('Cleaning up WebSocket connection...');
      ws.close();
    };
  }, [access_token, username]); // Ensure the WebSocket is set up only when access_token and username are available

  // Function to send messages via WebSocket
  const sendMessage = (message) => {
    console.log('Attempting to send message:', message);

    if (socket && socket.readyState === WebSocket.OPEN) {
      console.log('WebSocket is open, sending message:', message);
      socket.send(JSON.stringify({ message, author: username })); // Include the author information when sending the message

      // Add the sent message to the state with the current user's username
      setMessages((prevMessages) => [...prevMessages, { text: message, sent: true, author: username }]);
    } else {
      console.error('WebSocket is not connected or open. Current readyState:', socket?.readyState);
    }
  };

  // Show a message if the user is not authenticated
  if (!access_token) {
    return (
      <div className='chat-container'>
        <Sidebar />
        <div className="no-access">
          <h2>Access Denied</h2>
          <p>You must be logged in to use the chat features. Please log in and try again.</p>
        </div>
      </div>
    );
  }

  return (
    <div className='chat-container'>
      <ChatArea sendMessage={sendMessage} messages={messages} /> {/* Pass the sendMessage and messages */}
    </div>
  );
};

export default Chatapplocal;
